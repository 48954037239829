<template>
  <div class="page-product-details page-content">
    <div class="body">
      <template v-if="club.state === 'active'">
        <p class="page-subtitle">
          Which membership package are you looking to subscribe to?
        </p>
        <transition name="fade-bottom" mode="out-in">
          <div v-if="isLoading" class="page-product-details-loading">
            <KlubbaLoaderIcon :active="true" />
          </div>
          <klubba-carousel
            v-else
            :subscriptions="subscriptions"
            v-model="cardIndex"
          />
        </transition>
      </template>
      <template v-else>
        <klubba-rich-text>
          <p><b>Sorry, this club is suspended and it's not possible to subscribe to it.</b></p>
          <p>Please <a href="mailto:support@myklubba.com" target="_blank">contact support</a> for more details.</p>
        </klubba-rich-text>
      </template>
    </div>

    <div class="page-product-details-button">
      <klubba-button
        v-if="club.state !== 'inactive'"
        :disabled="cardIndex === -1"
        buttonText="Subscribe with"
        customClass="bg-primary"
        childClass="bg-primary-dark ml-3 py-1 px-3 flex items-center rounded-radius-10 text-white"
        icon="logo.svg"
        iconClass="h-4 mr-3"
        childText="KLUBBA"
        @click="handleSubscribe"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaCarousel from '@/components/custom/KlubbaCarousel.vue';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';

export default {
  name: 'Product Details',
  components: {
    KlubbaRichText,
    KlubbaLoaderIcon,
    KlubbaButton,
    KlubbaCarousel,
  },
  data() {
    return {
      isLoading: false,
      cardIndex: 0,
    };
  },
  computed: {
    ...mapState(['club', 'firebaseUser']),
    clubId() {
      return this.club.id;
    },
    subscriptions() {
      return this.$store.getters.clubSubscriptions;
    },
  },
  methods: {
    handleSubscribe() {
      this.$router.push(`/${this.club.id}/add-wallet`);
    },
  },
};
</script>

<style lang="sass" scoped>
.body
  @apply pt-8 px-8

.page-subtitle
  @apply leading-5 tracking-spacing-7 pb-8

.page-product-details-button
  @apply p-8 mt-auto

.page-product-details-loading
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  min-height: 330px
  @apply w-full

.suspended-club-text
  font-size: inherit
</style>
