<template>
  <div class="carousel-wrap">
    <div class="klubba-carousel">
      <swiper v-if="computedSubscriptions" :slides-per-view="1" :space-between="8" :pagination="{ clickable: true }">
        <swiper-slide v-for="(item, index) in computedSubscriptions" :key="index">
          <klubba-product-card
            :index="index"
            :isChecked="modelValue === index"
            :product="item"
            @click="getActiveSlide(index)"
          />
        </swiper-slide>
      </swiper>
      <div v-else class="not-found">Sorry, but there are no available subscriptions for you. Please contact your club.</div>
    </div>
  </div>
</template>

<script>
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Other imports
import { mapActions, mapGetters } from 'vuex';
import KlubbaProductCard from '@/components/custom/KlubbaProductCard.vue';

// Add swiper plugin
SwiperCore.use([Pagination]);

export default {
  name: 'KlubbaCarousel',
  props: {
    subscriptions: {
      type: Array,
    },
    modelValue: {
      type: Number,
    },
  },
  components: {
    KlubbaProductCard,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(['preSelectedSubscriptionId']),
    computedSubscriptions() {
      if (this.preSelectedSubscriptionId) {
        const subscription = this.subscriptions.find((product) => product.id === this.preSelectedSubscriptionId);
        return subscription ? [subscription] : null;
      }
      return this.subscriptions;
    },
  },
  methods: {
    ...mapActions([
      'saveProductCard',
      'saveProductPrice',
      'saveChosenSubscription',
    ]),
    async getActiveSlide(index) {
      // eslint-disable-next-line no-unused-expressions
      this.modelValue === index ? this.$emit('update:modelValue', -1) : this.$emit('update:modelValue', index);
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        // eslint-disable-next-line no-unused-expressions
        value === -1 ? this.$store.commit('setNewSelectedSubscriptionId', null) : this.$store.commit('setNewSelectedSubscriptionId', this.subscriptions[value].id);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.klubba-carousel
  @apply text-black-dark w-full

.not-found
  @apply m-0 p-7 w-full bg-white rounded-radius-10 cursor-pointer text-danger text-center
  border: 1px solid rgba(0, 0, 0, 0.1)
  line-height: 1.5
  font-size: 1rem

.klubba-carousel :deep(.swiper-slide)
  @apply m-0 p-4 w-full bg-white rounded-radius-10 cursor-pointer
  min-height: 20rem
  border: 1px solid rgba(0, 0, 0, 0.1)
  opacity: .5
  transition: opacity var(--transition-2x)

  &.swiper-slide-active
    opacity: 1

.klubba-carousel :deep(.swiper-container)
  display: flex
  flex-flow: column nowrap
  overflow: visible

.klubba-carousel :deep(.swiper-pagination)
  @apply flex justify-center items-center
  order: 2
  margin-top: 23px
  position: initial
  gap: 8px

.klubba-carousel :deep(.swiper-pagination-bullet)
  @apply w-2 h-2 rounded-full inline-block bg-white hover:bg-gray-light m-0
  @apply border
  transition: background-color var(--transition)
  opacity: 1
  border-color: var(--disabled-fore-color, var(--klubba-disabled-fore-color))

.klubba-carousel :deep(.swiper-pagination-bullet-active)
  background-color: var(--disabled-fore-color, var(--klubba-disabled-fore-color))
</style>
